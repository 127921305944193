<template>
  <div class="header has-background-white">
    <div class="gf-container">
      <div id="navigation-icon" :class="{'nav-icon--active': isNavOpen}" class="is-hidden-desktop">
        <div class="level-left logo-area">
          <router-link to="/" v-if="!isNavOpen">
            <img
              alt="gigforce logo"
              class="mainlogo mainlogo-mobile"
              src="../assets/gigforce.png"
              id="gigforce-logo"
            />
          </router-link>
        </div>
        <div class="level-right menu-icon-mobile">
          <img id="menu-btn" class="creative" alt="" src="../assets/menu.png" @click="isNavOpen = true;" v-if="!isNavOpen"/>
          <img id="menu-btn-cancel" src="../assets/cancel.png" v-if="isNavOpen" @click="isNavOpen = false;"/>
        </div>
      </div>

      <div id="mobnav" class="is-hidden-desktop">
        <div class="mobile-navbar-container" v-if="isNavOpen">
          <router-link to="/">
            <button class="button mobile-btn">Home</button>
          </router-link>
          <router-link to="/business">
            <button class="button mobile-btn">I am a Business</button>
          </router-link>
          <router-link to="/partners">
            <button class="button mobile-btn">Partners</button>
          </router-link>
          <router-link to="/sign-up">
            <button class="button mobile-btn">Signup</button>
          </router-link>
        </div>
      </div>

      <div id="nav" class="is-hidden-touch level mx-4">
        <div class="level-left logo-area">
          <router-link to="/">
            <img
              alt="gigforce logo"
              class="mainlogo"
              src="../assets/gigforce.png"
            />
          </router-link>
        </div>

        <div class="level-right nav-items">
          <router-link to="/">
            <button class="button is-white">Home</button>
          </router-link>
          <router-link to="/business">
            <button class="button is-white">I am a Business</button>
          </router-link>
          <router-link to="/partners">
            <button class="button is-white">Partners</button>
          </router-link>
          <router-link to="/sign-up">
            <button class="button is-primary singup-btn">Signup</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    isNavOpen: false
  }),
  methods: {
    toggle: function () {
    },
  },
};
</script>


<style lang="scss">
.header {
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;

  .mainlogo {
    width: 250px;
  }

  .logo-area {
    margin-left: 20px;
    background-image: url("../assets/logo-background-new.png");
  }
  .button {
    font-size: 18px;
  }
}

#menu-btn {
  display: flex;
  width: 20px;
  height: 20px;
}

#menu-btn-cancel {
  width: 20px;
  height: 20px;
}

.mobile-navbar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #d71f63;
  height: 100vh;
  width: 100%;
}
.mobile-btn {
  outline: none;
  background: transparent;
  border: none;
  color: white;
}

.mobile-btn:active {
  font-weight: bold;
  color: white;
  font-size: 14px;
}

.menu-icon-mobile {
  padding: 14px;
}

#navigation-icon {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
}

.nav-icon--active {
  background-color: #d71f63;
}

#mobnav {
  width: 100%;
  justify-content: flex-end;
}

.singup-btn {
  height: 2em !important;
  padding: 17px 40px;
  background-color: #d71f63 !important;
}

#nav {
  padding: 20px;
}

@media only screen and (max-width: 1023px) {
  #gigforce-logo {
    width: 150px;
  }
}
</style>
