import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/partners',
    name: 'partners',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/partners.vue')
},
{
    path: '/business',
    name: 'business',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/business.vue')
},
{
    path: '/sign-up',
    name: 'Signup',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/sign-up.vue')
},
{
    path: '/staffing-agency-in-india',
    name: 'Marketing',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/staffing-agency-in-india.vue')
},
{
    path: '/Media',
    name: 'Media',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Media.vue')
},
{
    path: '/case-studies',
    name: 'Casestudies',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/case-studies.vue')
},
// {
//     path: '/team',
//     name: 'team',
//     component: () =>
//         import( /* webpackChunkName: "about" */ '../views/team.vue')
// },
{
    path: '/blog',
    name: 'blog',
    component: () =>
        import( /*webpackChunkName: "about" */ '../views/blog.vue')
},
{
    path: '/users/:id',
    name: 'users',
    component: () =>
        import( /*webpackChunkName: "about" */ '../views/userProfile.vue')
},
{
    path: '/kyc/:id',
    name: 'kyc',
    component: () =>
        import( /*webpackChunkName: "about" */ '../views/kyc.vue')
},
{
    path: '/verify/kyc/:id/:tid',
    name: 'kyc',
    component: () =>
        import( /*webpackChunkName: "about" */ '../views/kycVerify.vue')
},
{
    path: '/MediaPage',
    name: 'MediaPage',
    component: () =>
        import( /*webpackChunkName: "about" */ '../views/MediaPage.vue')
},
{
    path: '/profile/:userid',
    name: 'Profile',
    component: () =>
        import( /*webpackChunkName: "about" */ '../views/profile.vue')
},
{
    path: '/terms-of-use',
    name: 'Profile',
    component: () =>
        import( /*webpackChunkName: "about" */ '../views/tnc.vue')
},

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router