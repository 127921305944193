
const basePath = `https://dk2gichyyc.execute-api.ap-south-1.amazonaws.com/dev`
// const basePath = `https://dk2gichyyc.execute-api.ap-south-1.amazonaws.com/dev`
// const basePath = `http://localhost:3300`

const prodPath = `https://eitrmt5q4k.execute-api.ap-south-1.amazonaws.com/prod`
// const prodPath = `https://v6ha2ldpw9.execute-api.ap-south-1.amazonaws.com/dev`

const playStoreURL = 'https://play.google.com/store/apps/details?id=com.gigforce.app'
export {
    basePath ,
    prodPath ,
    playStoreURL
};