<template>
  <div class="home container-main">
    <my-header></my-header>
    <section id="home-top-section" class="gf-container padding--xl mar--t">
      <div class="columns is-gapless is-vcentered mobile-col-rev padding--l">
        <div class="column text-left padding--l-l txt--l">
          <div class="display--1 margin--element">
            On demand staffing platform
          </div>

          <div class="display--2 margin--element">
            We are reinventing the ways people will earn & learn in future.
          </div>

          <div class="display--3 gf-color--light">
            Our mission is to help people learn, earn, grow & enjoy their life!
          </div>

          <div class="py-4">
            <router-link to="/sign-up">
              <button
                class="button is-primary is-large"
                style="background-color: #d71f63"
              >
                Join Today
              </button>
            </router-link>

            <a :href="appUrl" class="ml-5" target="_blank">
              <img
                class="pointer"
                id="playstore"
                alt="App url"
                src="../assets/icon/play_store.png"
                width="50"
              />
            </a>
          </div>
        </div>

        <div class="column is-three-fifths">
          <img
            id="mainTitleImg"
            alt="happy part-time workers"
            src="../assets/title-section-image.png"
          />
        </div>
      </div>
    </section>
    <!-- how it works -->
    <section id="how-it-works">
      <div class="columns is-vcentered">
        <div class="column">
          <img
            class="creative"
            alt=""
            src="../assets/gigforce-creative-001.png"
          />
        </div>
        <div class="column text-col text-center" style="padding-right: 12%">
          <div class="pointers">
            <div class="title--1">
              {{ howItWorks.heading }}
            </div>
            <hr />
            <div class="display--3">
              {{ howItWorks.desc }}
            </div>

            <div
              class="pointer mt-6 mb-2"
              v-for="pointer of howItWorks.pointers"
              :key="pointer.heading"
            >
              <div class="columns ml-4 htw-card">
                <div class="column is-2 is-centered has-text-right">
                  <figure class="image is-48x48">
                    <img alt="" :src="pointer.imageSrc" />
                  </figure>
                </div>
                <div class="column">
                  <div class="display--2 dark">
                    {{ pointer.heading }}
                  </div>
                  <div class="display--3">
                    {{ pointer.desc }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- benefits for you -->
    <section id="benefits-for-you">
      <div class="columns mobile-col-rev">
        <div class="column is-half text-col text-center benefits-txt-container">
          <div class="pointers text-left benefits-mt padding--xl">
            <div class="title--1">
              {{ benefitsForYou.heading }}
            </div>
            <hr />

            <div class="title--2" style="margin: 60px 0px 15px 25px">
              {{ benefitsForYou.giger.heading }}
            </div>
            <div class="display--3 --light">
              <div style="margin: 0px 0px 15px 25px">
                {{ benefitsForYou.giger.desc1 }}
              </div>
              <div style="margin: 0px 0px 15px 25px">
                {{ benefitsForYou.giger.desc2 }}
              </div>
              <div style="margin: 0px 0px 15px 25px">
                {{ benefitsForYou.giger.desc3 }}
              </div>
            </div>

            <div class="title--2" style="margin: 60px 0px 15px 25px">
              {{ benefitsForYou.business.heading }}
            </div>
            <div class="display--3 --light">
              <div style="margin: 0px 0px 15px 25px">
                {{ benefitsForYou.business.desc1 }}
              </div>
              <div style="margin: 0px 0px 15px 25px">
                {{ benefitsForYou.business.desc2 }}
              </div>
              <div style="margin: 0px 0px 15px 25px">
                {{ benefitsForYou.business.desc3 }}
              </div>
            </div>
          </div>
        </div>

        <div class="column is-half">
          <img
            class="creative cash-img"
            alt=""
            src="../assets/Group-1919.png"
          />
        </div>
      </div>
    </section>

    <!-- Why Gig Model -->
    <section id="why-gig-Model">
      <div class="columns is-vcentered">
        <div class="column">
          <img class="creative" alt="" src="../assets/Group-1924.png" />
        </div>
        <div class="column text-col mar--t text-center">
          <div class="pointers padding--xl">
            <div class="title--1">
              {{ whyGigModel.heading }}
            </div>
            <hr />
            <div
              class="block pointer mt-4 mb-5"
              v-for="pointer of whyGigModel.pointers"
              :key="pointer"
            >
              <div class="item-holder is-flex-direction-row">
                <img
                  width="30"
                  class="only--desktop"
                  src="../assets/Group-1927.png"
                />
                <img
                  class="only--mobile checkpoint-mobile"
                  src="../assets/Group-1927.png"
                />

                <span class="display--3 pl-4 is-align-self-center">
                  {{ pointer }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section--spacing"></section>
    <!-- about us -->
    <section id="aboutUs" class="container">
      <div class="column">
        <div class="title--1 a-t-center">
          {{ aboutUs.heading }}
        </div>

        <div>
          <img alt="gigforce logo" class="logo" src="../assets/gigforce.png" />
        </div>
        <div class="is-size-3 mt-30 subheading a-t-center">
          {{ aboutUs.subheading }}
        </div>

        <div class="grpImg">
          <img alt="group img" height="600" src="../assets/Group-1923.png" />
        </div>
      </div>
    </section>

    <!-- team -->
    <section id="team" class="container padding--m--20">
      <div class="text-left p--l--10">
        <div class="title--1">
          {{ team.heading }}
        </div>
        <hr />
      </div>

      <div class="columns mx-5 is-vcentered">
        <div
          class="column has-text-centered is-align-self-flex-start"
          v-for="(member, index) in team.members"
          :key="index + 'team'"
        >
          <img :src="member.img" />

          <div class="mt title--2">
            {{ member.name }}
          </div>
          <div class="mt display--3">
            {{ member.designation }}
          </div>
          <div class="mt text">
            {{ member.des }}
          </div>
        </div>
      </div>
    </section>
    <my-footer></my-footer>
  </div>
</template>

<script>
import header from "../components/Header";
import footer from "../components/Footer";
import { playStoreURL } from "../service/config";

export default {
  name: "Home",
  metaInfo: {
    title: "Find Highly Qualified Fexible Staff in India Easily | Gigforce",
    meta: [
      {
        name: "description",
        content:
          "Choose Gigforce as your complete workfoce management solution. Maximise profit by hiring pre-skilled workers, administration and payrolling for your operations.",
      },
    ],
  },
  data: () => ({
    howItWorks: {
      heading: "How it works?",
      desc:
        "Gigforce helps you find work when and where you want based on your strengths to maintain your financial freedom.",
      imageSrc: "/img/creatives/gigforce-creative-signup.png",
      pointers: [
        {
          imageSrc: "/img/creatives/gigforce-creative-signup.png",
          heading: "Signup",
          desc:
            "Tell us about your skills and your work, and salary preferences.",
        },
        {
          imageSrc: "/img/creatives/gigforce-creative-explore.png",
          heading: "Explore",
          desc:
            "Explore your preferred gigs, other career options and network with other gigers.",
        },
        {
          imageSrc: "/img/creatives/gigforce-creative-learn.png",
          heading: "Learn",
          desc:
            "Advance your current skills or develop new ones on the learning platform in the app.",
        },
        {
          imageSrc: "/img/creatives/gigforce-creative-earn.png",
          heading: "Earn",
          desc: "Earn and have a career with us.",
        },
      ],
    },

    benefitsForYou: {
      heading: "Benefits for you",
      giger: {
        heading: "For Gigers",
        desc1: `Be your own boss`,
        desc2: `Learn and earn`,
        desc3: `have multiple careers`,
      },
      business: {
        heading: "For Businesses",
        desc1: `Just in-time hiring `,
        desc2: `Trained & engaged workforce`,
        desc3: `No paper work or payroll to manage`,
      },
    },

    aboutUs: {
      heading: `The on-demand staffing platform connecting businesses with flexible workers.`,
      subheading: `The future of staffing.`,
    },
    team: {
      heading: `Our Team`,
      members: [
        {
          name: "Chirag Mittal",
          designation: "Co-founder & CEO",
          des: `11 Yrs+exp. in Tech & Entrepreneurship. M.Tech, B.Tech from IIT-Kanpur. #Entrepreneur #Deep #StaffingExposure.`,
          img: `img/team/chirag.png`,
        },
        {
          name: "Anirudh Syal",
          designation: "Co-founder & CPO",
          des: `11 Yrs+expin Operations & Data. B. Tech, IIT-Kanpur. MBA, ISB. Huge Passion & Experience with Operations. Giger since two Years.`,
          img: `img/team/anirudh.png`,
        },
        {
          name: "Ankur Garg",
          designation: "COO",
          des: `15+ experience in Management Consulting, Leadership and Marketing strategy.#IITK #ISB #groupM #marketing #mba`,
          img: `img/team/ankur.png`,
        },
      ],
    },
    whyGigModel: {
      heading: "Why Gig Model",
      pointers: [
        "More than 1 million gigers in top 10 metro cities",
        "Easy access to internet",
        "Mobile friendly work culture",
        "Increasing demand for flexible work days and time.",
        "Decreasing demand for permanent staff",
      ],
    },
    queryValue: "",
  }),
  methods: {
    goToPlayStore() {
      alert(JSON.stringify());
    },
  },
  mounted() {
    for (let key of Object.keys(this.$route.query))
      this.queryValue += `&${key}=${this.$route.query[key]}`;

    // if(this.queryValue.length)
    //     this.queryValue[0] = '?'
  },
  computed: {
    appUrl: function(){
      return playStoreURL + this.queryValue
    },
  },
  components: {
    myFooter: footer,
    myHeader: header,
  },
};
</script>

<style lang="scss">
.home {
  font-family: "Poppins", sans-serif;
  color: rgb(24, 24, 24);
  overflow-x: hidden;
  margin-top: 8%;
}
.pointer {
  cursor: pointer;
}
.home-container {
  width: 80%;
  margin: 0 auto;
}

.text-left {
  text-align: left;
}

.is-48x48 {
  img {
    height: 48px;
    width: auto;
  }
}

#mainTitleImg {
  height: 498px;
}

#how-it-works {
  min-height: 500px;
  font-family: "Poppins", sans-serif;

  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
    text-align: left;
  }

  .pointers {
    padding: 1rem;

    .pointer {
      display: block;
      .figure {
        width: 48px;
      }
      .heading {
        font-size: 24px;
        font-weight: bold;
      }

      .desc {
        font-size: 16px;
      }
    }
  }

  .creative {
    position: relative;
    left: 0px;
  }
}

#benefits-for-you {
  min-height: 500px;
  .text-col {
    margin-top: 5rem;
    padding-left: 60px;
  }
  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
    text-align: left;
  }
  .section-desc {
    color: #93007c;
    font-weight: 500;
    margin-top: 30px;
  }
  .desc {
    font-size: 22px;
    font-weight: 300;
    line-height: 2;
    color: #647484;
  }

  .mt {
    margin-top: 100px;
  }
}
.benefits-mt {
  margin-top: 100px;
}
#why-gig-Model {
  min-height: 500px;
  font-family: "Poppins", sans-serif;

  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    padding: 0;
    text-align: left;
  }
  .points {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 10px;
    margin-left: 10px;
  }
  .item-holder {
    display: flex;
    align-items: center;
  }
  .creative {
    position: relative;
    left: 0px;
  }
}

#aboutUs {
  margin-top: 30px;
  min-height: 500px;
  font-family: "Poppins", sans-serif;
  .logo {
    margin: 30px auto 0 auto;
    margin-top: 30px;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .subheading {
    color: #d71f63;
    font-weight: 700;
  }
  .grpImg {
    margin-top: 30px;
  }
}

#team {
  min-height: 550px;
  font-family: "Poppins", sans-serif;
  margin-top: 100px;
  margin-bottom: 30px;
  text-align: left;
  hr {
    margin-top: 2px;
    width: 130px;
    height: 3px;
    background-color: #d71f63;
  }
  .column {
    margin-right: 20px;
  }
  .mt {
    margin-top: 10px;
  }

  .row {
    display: flex;
    text-align: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .htw-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pointers {
    width: 90%;
    margin: 0 auto;
  }
  #mainTitleImg {
    width: 100%;
    height: unset;
  }

  #benefits-for-you {
    .text-col {
      margin-left: 1rem !important;
      margin-top: 2.3rem !important;
    }
  }
}
</style>