<template>
  <footer class="footer has-background-primary has-text-white">
    <div class="gf-container">
      <div class="columns is-vcentered mx-5">
        <div class="column text-left">
          <div>
            <img
              class="logo-img"
              src="../assets/logo-white.png"
              width="250"
              alt="Gigforce logo"
            />
          </div>
          <button class="button is-primary" @click="goToGig()">Giger</button>

          <router-link class="link" to="/business">
            <button class="button is-primary">Business</button>
          </router-link>
          <router-link class="link" to="/partners">
            <button class="button is-primary">Partner</button>
          </router-link>
        </div>

        <div class="column text-left qck-lnks">
          <!-- <div class="head is-size-4">Quick Links</div>
        <div class="mt-2">
          <ul>
             <li><button class="button is-primary">About Us</button></li>
             <li><button class="button is-primary">Blog</button></li>
             <li><button class="button is-primary">Contact</button></li>
             <li><button class="button is-primary">FAQ</button></li>
          </ul>
        </div> -->
        </div>

        <div class="column text-left">
          <!-- <ul>
           <li><button class="button is-primary">Disclaimer</button></li>
           <li><button class="button is-primary">Privacy Policy</button></li>
           <li><button class="button is-primary">Terms of Service</button></li>
        </ul> -->
        </div>

        <div class="column text-left">
          <p class="mt cnt-us">Connect us at :</p>
          <span class="social-icons">
            <a href="https://www.facebook.com/gig4ce" target="_blank"
              ><img src="img/social/facebook.svg" alt="fb"
            /></a>
            <a href="https://www.linkedin.com/company/gigforce/" target="_blank"
              ><img src="img/social/linkedin.svg" alt="ln"
            /></a>
            <a href="https://twitter.com/gig4ce" target="_blank"
              ><img src="img/social/twitter.svg" alt="tw"
            /></a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
var VueScrollTo = require("vue-scrollto");

export default {
  methods: {
    goToGig() {
      this.$router.push({ name: "Home" });
      setTimeout(() => {
        VueScrollTo.scrollTo("#how-it-works", 1000);
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.footer {
  background-image: linear-gradient(
    135deg,
    rgb(215, 31, 99) 0%,
    rgb(140, 0, 124) 100%
  );
  .link {
    display: block;
  }
  .qck-lnks {
    margin-top: 40px;
  }

  .button {
    background: transparent;
    font-size: 16px;
  }

  .head {
    font-size: 25px;
    font-weight: 700;
  }

  .mt-2 {
    margin-top: 15px;
  }
  .cnt-us {
    font-size: 16px;
    font-weight: bold;
  }
  .social-icons img {
    margin-top: 10px;
    margin-right: 18px;
    width: 10%;
  }
  .logo-img {
    margin-bottom: 42px;
  }
  .head {
    font-size: 20px !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
  .footer .social-icons img{
   width: 5%;
 }
}
</style>
